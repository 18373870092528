import React from "react";

import LayoutBase from "../components/LayoutBase";
import SimpleHeader from "../components/SimpleHeader";
import SimpleFooter from "../components/SimpleFooter";
import SEO from "../components/seo";

import styles from "./legal.module.css";

const Privacy = () => (
  <LayoutBase>
    <SEO title="Privacy - FitLab" />
    <SimpleHeader />
    <div className={styles.Legal}>
      <div>
        <h2>Privacy Policy</h2>
        <div>
          <p>
            At FitLab your privacy is very important to us. This Privacy Policy
            describes how personal data collected from users of{" "}
            <a title="FitLab LLC" href="https://thefitlab.io">
              https://thefitlab.io
            </a>{" "}
            is used and disclosed by FitLab LLC.
          </p>
          <h2>Types of data collected</h2>
          <p>
            Among the types of Personal Data that this Website collects, by
            itself or through third parties, there are: Cookie, Usage Data and
            Email.
          </p>
          <p>
            Other Personal Data collected may be described in other sections of
            this privacy policy or by dedicated explanation text contextually
            with the Data collection.
          </p>
          <p>
            The Personal Data may be freely provided by the User, or collected
            automatically when using this Website.
          </p>
          <p>
            Any use of Cookies – or of other tracking tools – by this Website or
            by the owners of third party services used by this Website, unless
            stated otherwise, serves to identify Users and remember their
            preferences, for the sole purpose of providing the service required
            by the User.
          </p>
          <p>
            Failure to provide certain Personal Data may make it impossible for
            this Website to provide its services.
          </p>
          <p>
            The User assumes responsibility for the Personal Data of third
            parties published or shared through this Website and declares to
            have the right to communicate or broadcast them, thus relieving the
            Data Controller of all responsibility.
          </p>
          <h2>Mode and place of processing the data</h2>
          <h3>Methods of processing</h3>
          <p>
            The Data Controller processes the Data of Users in a proper manner
            and shall take appropriate security measures to prevent unauthorized
            access, disclosure, modification, or unauthorized destruction of the
            Data.
          </p>
          <p>
            The Data processing is carried out using computers and/or IT enabled
            tools, following organizational procedures and modes strictly
            related to the purposes indicated. In addition to the Data
            Controller, in some cases, the Data may be accessible to certain
            types of persons in charge, involved with the operation of the site
            (administration, sales, marketing, legal, system administration) or
            external parties (such as third party technical service providers,
            mail carriers, hosting providers, IT companies, communications
            agencies) appointed, if necessary, as Data Processors by the Owner.
            The updated list of these parties may be requested from the Data
            Controller at any time.
          </p>
          <h3>Place</h3>
          <p>
            The Data is processed at the Data Controller&apos;s operating
            offices and in any other places where the parties involved with the
            processing are located. For further information, please contact the
            Data Controller.
          </p>
          <h3>Retention time</h3>
          <p>
            The Data is kept for the time necessary to provide the service
            requested by the User, or stated by the purposes outlined in this
            document, and the User can always request that the Data Controller
            suspend or remove the data.
          </p>
          <h2>The use of the collected data</h2>
          <p>
            The Data concerning the User is collected to allow the Application
            to provide its services, as well as for the following purposes:
            Analytics, Managing email addresses and sending messages and SPAM
            protection.
          </p>
          <p>
            The Personal Data used for each purpose is outlined in the specific
            sections of this document.
          </p>
          <h2>Detailed information on the processing of Personal Data</h2>
          <p>
            Personal Data is collected for the following purposes and using the
            following services:
          </p>
          <h3>Analytics</h3>
          <p>
            The services contained in this section enable the Owner to monitor
            and analyze web traffic and can be used to keep track of User
            behavior.
          </p>
          <h4>
            <a href="http://analytics.google.com" title="Google Analytics">
              Google Analytics
            </a>
          </h4>
          <p>
            Google Analytics is a web analysis service provided by Google Inc.
            (“Google”). Google utilizes the Data collected to track and examine
            the use of this Website, to prepare reports on its activities and
            share them with other Google services.
          </p>
          <p>
            Google may use the Data collected to contextualize and personalize
            the ads of its own advertising network.
          </p>
          <p>Personal Data collected: Cookie and Usage Data.</p>
          <p>
            Place of processing : USA –
            <a
              href="http://www.google.com/intl/en/policies/privacy/"
              title="Google Analytics Privacy Policy"
            >
              Privacy Policy
            </a>{" "}
            –
            <a
              href="https://tools.google.com/dlpage/gaoptout?hl=en"
              title="Google Analytics Opt-Out"
            >
              Opt Out
            </a>
          </p>
          <h3>Managing email addresses and sending messages</h3>
          <p>
            These services make it possible to manage a database of email
            contacts to communicate with the User via email. The services are
            used to collect data concerning the date and time when the mail is
            viewed by the User, as well as when the User interacts with incoming
            mail, such as by clicking on links included in the email.
          </p>
          <h4>
            <a href="https://mailchimp.com" title="MailChimp">
              MailChimp
            </a>
          </h4>
          <p>
            MailChimp is an email address management and marketing automation
            tool.
          </p>
          <p>Personal Data collected: Email.</p>
          <p>
            Place of processing : USA –
            <a
              href="https://mailchimp.com/legal/privacy/"
              title="MailChimp Privacy Policy"
            >
              Privacy Policy
            </a>
          </p>
          <h2>Additional information about Data collection and processing</h2>
          <h3>Legal action</h3>
          <p>
            The User&apos;s Personal Data may be used for legal purposes by the
            Data Controller, in Court or in the stages leading to possible legal
            action arising from improper use of this Website or the related
            services.
          </p>
          <p>
            The User is aware of the fact that the Data Controller may be
            required to reveal personal data upon request of public authorities.
          </p>
          <h3>Additional information about User&apos;s Personal Data</h3>
          <p>
            In addition to the information contained in this privacy policy,
            this Website may provide the User with additional and contextual
            information concerning particular services or the collection and
            processing of Personal Data upon request.
          </p>
          <h3>System Logs and Maintenance</h3>
          <p>
            For operation and maintenance purposes, this Website and any third
            party services may collect files that record interaction with this
            Website (System Logs) or use for this purpose other Personal Data
            (such as IP Address).
          </p>
          <h3>Information not contained in this policy</h3>
          <p>
            More details concerning the collection or processing of Personal
            Data may be requested from the Data Controller at any time. Please
            see the contact information at the beginning of this document.
          </p>
          <h3>The rights of Users</h3>
          <p>
            Users have the right, at any time, to know whether their Personal
            Data has been stored and can consult the Data Controller to learn
            about their contents and origin, to verify their accuracy or to ask
            for them to be supplemented, cancelled, updated or corrected, or for
            their transformation into anonymous format or to block any data held
            in violation of the law, as well as to oppose their treatment for
            any and all legitimate reasons. Requests should be sent to the Data
            Controller at the contact information set out above.
          </p>
          <p>This Website does not support “Do Not Track” requests.</p>
          <p>
            To determine whether any of the third party services it uses honor
            the “Do Not Track” requests, please read their privacy policies.
          </p>
          <h3>Changes to this privacy policy</h3>
          <p>
            The Data Controller reserves the right to make changes to this
            privacy policy at any time by giving notice to its Users on this
            page. It is strongly recommended to check this page often, referring
            to the date of the last modification listed at the bottom. If a User
            objects to any of the changes to the Policy, the User must cease
            using this Website and can request that the Data Controller erase
            the Personal Data. Unless stated otherwise, the then-current privacy
            policy applies to all Personal Data the Data Controller has about
            Users.
          </p>
          <h3>Information about this privacy policy</h3>
          <p>
            The Data Controller is responsible for this privacy policy, prepared
            starting from the modules provided by Iubenda and hosted on
            Iubenda&apos;s servers.
          </p>
          <h2>Definitions and legal references</h2>
          <h3>Personal Data</h3>
          <p>
            Any information regarding a natural person, a legal person, an
            institution or an association, which is, or can be, identified, even
            indirectly, by reference to any other information, including a
            personal identification number.
          </p>
          <h3>Usage Data</h3>
          <p>
            Information collected automatically from this Website (or third
            party services employed in this Website ), which can include: the IP
            addresses or domain names of the computers utilized by the Users who
            use this Website, the URI addresses (Uniform Resource Identifier),
            the time of the request, the method utilized to submit the request
            to the server, the size of the file received in response, the
            numerical code indicating the status of the server&apos;s answer
            (successful outcome, error, etc.), the country of origin, the
            features of the browser and the operating system utilized by the
            User, the various time details per visit (e.g., the time spent on
            each page within the Application) and the details about the path
            followed within the Application with special reference to the
            sequence of pages visited, and other parameters about the device
            operating system and/or the User&apos;s IT environment.
          </p>
          <h3>User</h3>
          <p>
            The individual using this Website, which must coincide with or be
            authorized by the Data Subject, to whom the Personal Data refer.
          </p>
          <h3>Data Subject</h3>
          <p>
            The legal or natural person to whom the Personal Data refers to.
          </p>
          <h3>Data Processor</h3>
          <p>
            The natural person, legal person, public administration or any other
            body, association or organization authorized by the Data Controller
            to process the Personal Data in compliance with this privacy policy.
          </p>
          <h3>Data Controller</h3>
          <p>
            The natural person, legal person, public administration or any other
            body, association or organization with the right, also jointly with
            another Data Controller, to make decisions regarding the purposes,
            and the methods of processing of Personal Data and the means used,
            including the security measures concerning the operation and use of
            this Website. The Data Controller, unless otherwise specified, is
            the Owner of this Website.
          </p>
          <h3>This Website</h3>
          <p>
            The hardware or software tool by which the Personal Data of the User
            is collected.
          </p>
          <h3>Cookie</h3>
          <p>Small piece of data stored in the User&apos;s device.</p>
          <h3>Legal information</h3>
          <p>This privacy policy relates solely to this Website.</p>
        </div>
      </div>
    </div>
    <SimpleFooter />
  </LayoutBase>
);

export default Privacy;
